import { RegionCode } from '@kaa/api/providers';
import { ValueOf } from '@kaa/common/types';
import { i18nKeys } from '@kaa/i18n/providers/keys';

export enum Modals {
  CONTACT_PERSON_MODAL_ID = 'modal-contact-person-id',
  CHANGE_EMAIL_MODAL_ID = 'modal-confirm-email-id',
  CHANGE_PASSWORD_MODAL_ID = 'modal-change-password-id',
  DISABLE_RELATIVE_MODAL_ID = 'modal-disable-relative-id',
  ADD_RELATIVE_MODAL_ID = 'modal-add-relative-id',
  CONFIRM_SIGNOUT_MODAL = 'modal-signout-confirm',
  REMITTANCES_SLIP_TEMPLATE_MODAL_ID = 'modal-remittances-slip-template-id',
  REMITTANCES_SLIP_MODAL_ID = 'modal-remittances-slip-id',
  REMITTANCES_CHECK_VALIDITY_MODAL_ID = 'modal-remittances-check-validity-id',
  REMITTANCES_MANUAL_MODAL_ID = 'modal-remittances-manual-id',
  REMITTANCES_MANUAL_CREATION_DOWNLOAD_MODAL = 'modal-remittances-manual-creation-download-id',
  REMITTANCES_AUTOMATIC_MODAL_ID = 'modal-remittances-automatic-id',
  REMITTANCES_VOUCHER_DECLARATION_ONE_BY_ONE_MODAL_ID = 'modal-remittances-voucher-declaration-one-by-one-id',
  REMITTANCES_VOUCHERS_DECLARATION_MODAL_ID = 'modal-remittances-vouchers-declaration-id',
  REMITTANCES_REIMBURSEMENT_MODAL_ID = 'modal-remittances-reimbursement-id',
  REMITTANCES_REIMBURSEMENT_DETAIL_MODAL = 'modal-remittances-reimbursement-detail-id',
  REMITTANCES_REIMBURSEMENT_DOWNLOAD_MODAL = 'modal-remittances-reimbursement-download-id',
  REMITTANCES_SEARCH_HISTORY_DOWNLOAD_MODAL = 'modal-remittances-search-history-download-id',
  REMITTANCES_SEARCH_HISTORY_DETAIL_MODAL = 'modal-remittances-search-history-detail-id',
  REMITTANCES_SEARCH_HISTORY_INCORRECT_MODAL = 'modal-remittances-search-history-incorrect-id',
  REMITTANCES_SEARCH_HISTORY_REFUSED_MODAL = 'modal-remittances-search-history-refused-id',
  PRESTATIONS_ADD_PRESTATION_MODAL = 'modal-prestations-add-prestation-id',
  PRESTATIONS_IMPORT_LIST_PRESTATION_MODAL = 'modal-prestations-import-list-prestation-id',
  PRESTATIONS_EXPORT_LIST_PRESTATION_MODAL = 'modal-prestations-export-list-prestation-id',
  PRESTATIONS_DETAILS_PRESTATION_MODAL = 'modal-prestations-details-prestation-id',
  PRESTATIONS_EDIT_PRESTATION_MODAL = 'modal-prestations-edit-prestation-id',
  PRESTATIONS_CANCEL_PRESTATION_MODAL = 'modal-prestations-cancel-prestation-id',
  PRESTATIONS_VALIDATE_PRESTATION_MODAL = 'modal-prestations-validate-prestation-id',
  PRESTATIONS_WORKER_VALIDATE_ALL_PRESTATIONS_MODAL = 'modal-prestations-worker-validate-all-prestations-id',
  PRESTATIONS_WORKERS_VALIDATE_ALL_PRESTATIONS_MODAL = 'modal-prestations-workers-validate-all-prestations-id',
  PICKUP_CREATE_PICKUP_MODAL = 'modal-pickup-create-pickup-id',
  PICKUP_UPDATE_PICKUP_MODAL = 'modal-pickup-update-pickup-id',
  PICKUP_RESUME_PICKUP_MODAL = 'modal-pickup-resume-pickup-id',
  PICKUP_DELETE_PICKUP_MODAL = 'modal-pickup-delete-pickup-id',
  PICKUP_TIPS_MODAL = 'modal-pickup-tips-id',
  PICKUP_BILLS_DETAIL_MODAL = 'modal-pickup-bills-detail-id',
  PICKUP_BILLS_DOWNLOAD_MODAL = 'modal-pickup-bills-download-id',
  WORKERS_ADD_WORKERLIST_MODAL = 'modal-workers-add-worker-list-id',
  WORKERS_ADD_WORKER_MODAL = 'modal-worker-add-worker-id',
  WORKERS_IMPORT_LIST_WORKER_MODAL = 'modal-workers-import-list-worker-id',
  WORKERS_EDIT_WORKER_MODAL = 'modal-worker-edit-worker-id',
  WORKERS_ADD_CONTRACT_MODAL = 'modal-worker-add-contract-id',
  WORKERS_DELETE_CONTRACT_MODAL = 'modal-worker-delete-contract-id',
  WORKERS_EDIT_CONTRACT_MODAL = 'modal-worker-edit-contract-id',
  WORKERS_CONFIRMATION_MODAL = 'modal-worker-confirmation-id',
  WORKERS_RESET_PASSWORD_MODAL = 'modal-worker-reset-password-id',
  WORKER_SEARCH_HISTORY_DOWNLOAD_MODAL = 'modal-search-history-download',
}

export enum ApplicationType {
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PDF = 'application/pdf',
  CSV = 'text/csv',
}

export enum Templates {
  IMPORT_WORKS = 'importWorks',
  IMPORT_WORKERS = 'importWorkers',
  VOUCHER_ANNOUNCEMENT = 'voucherAnnouncement',
  RELATIVE_BANK_ACCOUNT = 'relativeBankAccount',
}

// EXTERNAL LINKS

// footer top
export const REGION_QUESTION_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url.question,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url.question,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url.question,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_COMPLAINT_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url.complaint,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url.complaint,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url.complaint,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };
// footer + register
export const REGION_CONDITIONS_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url.termsAndConditions,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url.termsAndConditions,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url.termsAndConditions,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_ACCOUNT_FROZEN_BLOCKED_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url.accountFrozenBlocked,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url.accountFrozenBlocked,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url.accountFrozenBlocked,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_PRIVACY_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url.privacyPolicy,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url.privacyPolicy,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url.privacyPolicy,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };
// footer
export const REGION_COOKIES_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url.cookies,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url.cookies,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url.cookies,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_NEWS_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url.news,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url.news,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url.news,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };
// Support
export const REGION_CONTACT_US_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url.contactUs,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url.contactUs,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url.contactUs,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_FAQ_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url.faq,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url.faq,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url.faq,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const PASS_URL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.url,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.url,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.url,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_NAME = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.region.name,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.region.name,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.region.name,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_EMAIL = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.general.emailAddress,
  [RegionCode.BE_BRU]: i18nKeys.bl.general.emailAddress,
  [RegionCode.BE_WAL]: i18nKeys.wl.general.emailAddress,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_HELP_CENTER_NUMBER = ({
  [RegionCode.BE_VLG]:
    i18nKeys.vl.general.serviceVouchers.helpCenter.phoneNumber,
  [RegionCode.BE_WAL]:
    i18nKeys.wl.general.serviceVouchers.helpCenter.phoneNumber,
  [RegionCode.BE_BRU]:
    i18nKeys.bl.general.serviceVouchers.helpCenter.phoneNumber,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const APP_ID = ({
  [RegionCode.BE_VLG]: '4efe4594-5480-4653-ae93-7155d314c196',
  [RegionCode.BE_BRU]: '193916e5-2503-4186-a5e9-07545ebc3552',
  [RegionCode.BE_WAL]: '99886336-e442-4487-ab82-cd687e0dc13f',
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_REMITTANCES_REIMBURSEMENT_PDF = ({
  [RegionCode.BE_VLG]: i18nKeys.vl.remittances.reimbursement.pdf,
  [RegionCode.BE_BRU]: i18nKeys.bl.remittances.reimbursement.pdf,
  [RegionCode.BE_WAL]: i18nKeys.wl.remittances.reimbursement.pdf,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

/**
 * Used by AuthService and IDP to block login between customer/provider
 */
export const AUTH_TENANT = 'tenant:provider';
