import { SwLink } from '@kaa/ui-flanders/components';
import forremLogo from '@kaa/ui-flanders/themes/wl/images/logo.svg';
import logo from '@kaa/ui-flanders/themes/_shared/images/Pluxee_Logo_DarkBlue_RGB.png';
import React from 'react';

type HeaderLogoProps = {
  publicUrl: string;
  homeUrl: string;
  title: string;
  titleMobile: string;
  mainLogoAlt: string;
  logoAlt: string;
};

export const HeaderLogo = ({
  publicUrl,
  homeUrl,
  title,
  titleMobile,
  mainLogoAlt,
  logoAlt,
}: HeaderLogoProps) => {
  return (
    <>
      <SwLink to={publicUrl} className="site-bar__logo" target="_blank">
        <img
          src={forremLogo}
          alt={mainLogoAlt}
          className="site-bar__logo--image"
        />
      </SwLink>
      <SwLink to={homeUrl} className="site-bar__link">
        <div className="site-bar__separator">
          <span className="site-bar__title hideMobile">{titleMobile}</span>
          <span className="site-bar__title site-bar__mobileTitle showMobile">
            <img src={logo} alt={logoAlt} className="logo_pluxee" />
            {title}
          </span>
        </div>
      </SwLink>
    </>
  );
};
