import { RouteActive } from '@kaa/common/types';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { Icon, SwIcon } from '@kaa/ui-flanders/components';
import { TFunction } from 'i18next';
import React from 'react';
import { dataTest } from '../../datatest/keys';
import { Routes } from '../../routes';
import { WlFunctionHeaderTab } from './WlFunctionalHeader.types';

export const getDefaultTabs = (t: TFunction): WlFunctionHeaderTab[] => [
  {
    id: Routes.DASHBOARD,
    header: t(i18nKeys.navigation.dashboard),
    children: [
      {
        id: Routes.DASHBOARD,
        header: t(i18nKeys.navigation.dashboard),
        uid: '1',
      },
      {
        id: Routes.VIDEOS,
        header: t(i18nKeys.navigation.videos),
        uid: '2',
      },
    ],
    uid: '1',
  },
  {
    id: Routes.REMITTANCES,
    header: t(i18nKeys.navigation.remittances),
    children: [
      {
        id: Routes.REMITTANCES,
        header: t(i18nKeys.navigation.remittances),
        uid: '1',
      },
      {
        id: Routes.REMITTANCES_REIMBURSEMENT,
        header: t(i18nKeys.navigation.remittancesReimbursement),
        uid: '2',
      },
    ],
    uid: '2',
  },
  {
    id: Routes.PRESTATIONS,
    header: t(i18nKeys.navigation.prestations),
    children: [
      {
        id: Routes.PRESTATIONS,
        header: t(i18nKeys.navigation.prestations),
        uid: '1',
      },
      {
        id: Routes.PRESTATIONS_HISTORY,
        header: t(i18nKeys.navigation.prestationsHistory),
        uid: '2',
      },
      {
        id: Routes.PRESTATIONS_CUSTOMER_STATUS,
        header: t(i18nKeys.navigation.prestationsUserStatus),
        uid: '3',
      },
    ],
    uid: '3',
  },
  {
    id: Routes.WORKERS,
    header: t(i18nKeys.navigation.workers),
    uid: '4',
  },
  {
    id: Routes.PICKUP,
    header: t(i18nKeys.navigation.pickup),
    children: [
      {
        id: Routes.PICKUP,
        header: t(i18nKeys.navigation.pickup),
        uid: '1',
      },
      {
        id: Routes.PICKUP_BILLING,
        header: t(i18nKeys.navigation.pickupBilling),
        uid: '2',
      },
    ],
    uid: '5',
  },
  {
    id: Routes.ENTERPRISE,
    header: t(i18nKeys.navigation.enterprise),
    children: [
      {
        id: Routes.ENTERPRISE,
        header: t(i18nKeys.navigation.enterprise),
        uid: '1',
      },
      {
        id: Routes.ENTERPRISE_ACTIVITIES,
        header: t(i18nKeys.navigation.enterpriseActivities),
        uid: '2',
      },
    ],
    uid: '6',
  },
];

export const getTabsWorkerDetails = (t: TFunction): WlFunctionHeaderTab[] => [
  {
    id: Routes.DASHBOARD,
    header: t(i18nKeys.navigation.dashboard),
    uid: '1',
  },
  {
    id: Routes.REMITTANCES,
    header: t(i18nKeys.navigation.remittances),
    children: [
      {
        id: Routes.REMITTANCES,
        header: t(i18nKeys.navigation.remittances),
        uid: '1',
      },
      {
        id: Routes.REMITTANCES_REIMBURSEMENT,
        header: t(i18nKeys.navigation.remittancesReimbursement),
        uid: '2',
      },
    ],
    uid: '2',
  },
  {
    id: Routes.PRESTATIONS,
    header: t(i18nKeys.navigation.prestations),
    children: [
      {
        id: Routes.PRESTATIONS,
        header: t(i18nKeys.navigation.prestations),
        uid: '1',
      },
      {
        id: Routes.PRESTATIONS_HISTORY,
        header: t(i18nKeys.navigation.prestationsHistory),
        uid: '2',
      },
    ],
    uid: '3',
  },
  {
    id: Routes.WORKERS,
    header: t(i18nKeys.navigation.workers),
    children: [
      {
        id: Routes.WORKERS,
        'data-testid': dataTest.header.backWorkers,
        className: 'vl-link vl-u-display-inline-flex',
        header: (
          <>
            <SwIcon className="vl-link__icon" icon={Icon.ARROW_LEFT} />{' '}
            {t(i18nKeys.navigation.backToWorkers)}
          </>
        ),
        uid: '1',
      },
      {
        id: Routes.WORKERS_DETAILS,
        header: t(i18nKeys.navigation.workerDetails),
        uid: '2',
      },
    ],
    uid: '4',
  },
  {
    id: Routes.PICKUP,
    header: t(i18nKeys.navigation.pickup),
    children: [
      {
        id: Routes.PICKUP,
        header: t(i18nKeys.navigation.pickup),
        uid: '1',
      },
      {
        id: Routes.PICKUP_BILLING,
        header: t(i18nKeys.navigation.pickupBilling),
        uid: '2',
      },
    ],
    uid: '5',
  },
  {
    id: Routes.ENTERPRISE,
    header: t(i18nKeys.navigation.enterprise),
    uid: '6',
  },
];

export const getTabsEnterpriseDetails = (
  t: TFunction,
): WlFunctionHeaderTab[] => [
  {
    id: Routes.DASHBOARD,
    header: t(i18nKeys.navigation.dashboard),
    uid: '1',
  },
  {
    id: Routes.REMITTANCES,
    header: t(i18nKeys.navigation.remittances),
    children: [
      {
        id: Routes.REMITTANCES,
        header: t(i18nKeys.navigation.remittances),
        uid: '1',
      },
      {
        id: Routes.REMITTANCES_REIMBURSEMENT,
        header: t(i18nKeys.navigation.remittancesReimbursement),
        uid: '2',
      },
    ],
    uid: '2',
  },
  {
    id: Routes.PRESTATIONS,
    header: t(i18nKeys.navigation.prestations),
    children: [
      {
        id: Routes.PRESTATIONS,
        header: t(i18nKeys.navigation.prestations),
        uid: '1',
      },
      {
        id: Routes.PRESTATIONS_HISTORY,
        header: t(i18nKeys.navigation.prestationsHistory),
        uid: '2',
      },
    ],
    uid: '3',
  },
  {
    id: Routes.WORKERS,
    header: t(i18nKeys.navigation.workers),
    uid: '4',
  },
  {
    id: Routes.PICKUP,
    header: t(i18nKeys.navigation.pickup),
    children: [
      {
        id: Routes.PICKUP,
        header: t(i18nKeys.navigation.pickup),
        uid: '1',
      },
      {
        id: Routes.PICKUP_BILLING,
        header: t(i18nKeys.navigation.pickupBilling),
        uid: '2',
      },
    ],
    uid: '5',
  },
  {
    id: Routes.ENTERPRISE,
    header: t(i18nKeys.navigation.enterprise),
    children: [
      {
        id: Routes.ENTERPRISE,
        'data-testid': dataTest.header.backEnterprise,
        className: 'vl-link vl-u-display-inline-flex',
        header: (
          <>
            <SwIcon className="vl-link__icon" icon={Icon.ARROW_LEFT} />{' '}
            {t(i18nKeys.navigation.backToEnterprise)}
          </>
        ),
        uid: '1',
      },
      {
        id: Routes.ENTERPRISE_DETAILS,
        header: t(i18nKeys.navigation.enterpriseDetails),
        uid: '2',
      },
      {
        id: Routes.ENTERPRISE_DETAILS_CONTACT_PERSON,
        header: t(i18nKeys.navigation.contactPerson),
        uid: '3',
      },
      {
        id: Routes.ENTERPRISE_DETAILS_VISIBILITY,
        header: t(i18nKeys.navigation.enterpriseDetailsVisibility),
        uid: '4',
      },
    ],
    uid: '6',
  },
];

export const getTabsEnterpriseContactDetails = (
  t: TFunction,
): WlFunctionHeaderTab[] => [
  {
    id: Routes.DASHBOARD,
    header: t(i18nKeys.navigation.dashboard),
    uid: '1',
  },
  {
    id: Routes.REMITTANCES,
    header: t(i18nKeys.navigation.remittances),
    children: [
      {
        id: Routes.REMITTANCES,
        header: t(i18nKeys.navigation.remittances),
        uid: '1',
      },
      {
        id: Routes.REMITTANCES_REIMBURSEMENT,
        header: t(i18nKeys.navigation.remittancesReimbursement),
        uid: '2',
      },
    ],
    uid: '2',
  },
  {
    id: Routes.PRESTATIONS,
    header: t(i18nKeys.navigation.prestations),
    children: [
      {
        id: Routes.PRESTATIONS,
        header: t(i18nKeys.navigation.prestations),
        uid: '1',
      },
      {
        id: Routes.PRESTATIONS_HISTORY,
        header: t(i18nKeys.navigation.prestationsHistory),
        uid: '2',
      },
    ],
    uid: '3',
  },
  {
    id: Routes.WORKERS,
    header: t(i18nKeys.navigation.workers),
    uid: '4',
  },
  {
    id: Routes.PICKUP,
    header: t(i18nKeys.navigation.pickup),
    children: [
      {
        id: Routes.PICKUP,
        header: t(i18nKeys.navigation.pickup),
        uid: '1',
      },
      {
        id: Routes.PICKUP_BILLING,
        header: t(i18nKeys.navigation.pickupBilling),
        uid: '2',
      },
    ],
    uid: '5',
  },
  {
    id: Routes.ENTERPRISE,
    header: t(i18nKeys.navigation.enterprise),
    children: [
      {
        id: Routes.ENTERPRISE_DETAILS_CONTACT_PERSON,
        'data-testid': dataTest.header.backContactPerson,
        className: 'vl-link vl-u-display-inline-flex',
        header: (
          <>
            <SwIcon className="vl-link__icon" icon={Icon.ARROW_LEFT} />{' '}
            {t(i18nKeys.navigation.backToContactPerson)}
          </>
        ),
        uid: '1',
      },
      {
        id: Routes.ENTERPRISE_DETAILS_CONTACT_PERSON_DETAILS,
        header: t(i18nKeys.navigation.contactPersonDetails),
        uid: '2',
      },
    ],
    uid: '6',
  },
];

export const getLoginTabs = (t: TFunction): WlFunctionHeaderTab[] => [
  {
    id: Routes.LOGIN,
    header: t(i18nKeys.navigation.login),
    uid: '1',
  },
];

export const getLoginLocalTabs = (t: TFunction): WlFunctionHeaderTab[] => [
  {
    id: Routes.LOGIN_LOCAL,
    header: t(i18nKeys.navigation.login),
    uid: '1',
  },
];

export const isActiveTab = (
  tab: {
    id: Routes;
    children?: Array<{ id: Routes }>;
    active?: boolean;
  },
  activeRoute: RouteActive<Routes>,
) =>
  tab.active ||
  tab.id === activeRoute.route.id ||
  (tab.children && tab.children.some((tab) => activeRoute.route.id === tab.id));

export const getTabs = (
  route: Routes,
  t: TFunction,
): WlFunctionHeaderTab[] | undefined => {
  const TABS: Partial<
    { [key in Routes]: (t: TFunction) => WlFunctionHeaderTab[] }
  > = {
    [Routes.LOGIN]: getLoginTabs,
    [Routes.LOGIN_LOCAL]: getLoginLocalTabs,
    [Routes.LANDING]: getDefaultTabs,
    [Routes.DASHBOARD]: getDefaultTabs,
    [Routes.REMITTANCES]: getDefaultTabs,
    [Routes.REMITTANCES_REIMBURSEMENT]: getDefaultTabs,
    [Routes.PRESTATIONS]: getDefaultTabs,
    [Routes.PRESTATIONS_HISTORY]: getDefaultTabs,
    [Routes.PRESTATIONS_CUSTOMER_STATUS]: getDefaultTabs,
    [Routes.WORKERS]: getDefaultTabs,
    [Routes.VIDEOS]: getDefaultTabs,
    [Routes.WORKERS_DETAILS]: getTabsWorkerDetails,
    [Routes.PICKUP]: getDefaultTabs,
    [Routes.PICKUP_BILLING]: getDefaultTabs,
    [Routes.ENTERPRISE]: getDefaultTabs,
    [Routes.ENTERPRISE_ACTIVITIES]: getDefaultTabs,
    [Routes.ENTERPRISE_DETAILS]: getTabsEnterpriseDetails,
    [Routes.ENTERPRISE_DETAILS_CONTACT_PERSON]: getTabsEnterpriseDetails,
    [Routes.ENTERPRISE_DETAILS_CONTACT_PERSON_DETAILS]: getTabsEnterpriseContactDetails,
    [Routes.ENTERPRISE_DETAILS_VISIBILITY]: getTabsEnterpriseDetails,
  };

  const tab = TABS[route];

  return tab ? tab(t) : tab;
};
