import { CustomerStatus } from '@kaa/api/providers';
import { Icon } from '@kaa/ui-flanders/components';

export const getAlertPropsByCustomerStatus = (
  customerStatus: CustomerStatus,
) => {
  switch (customerStatus) {
    case CustomerStatus.ACTIVE:
      return {
        icon: Icon.CHECK_CIRCLE,
        modSuccess: true,
      };
    case CustomerStatus.BLOCKED:
    case CustomerStatus.FROZEN:
    case CustomerStatus.NOT_FOUND:
      return {
        icon: Icon.ALERT_CIRCLE,
        modError: true,
      };
    default:
      return {};
  }
};
