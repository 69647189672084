import {
  EventAction,
  EventCategory,
  sendCustomInteractionToGTM,
} from '@kaa/core-app/customers/utils/google-analytics'; // TODO MOVE ME TO COMMON
import {
  SwButton,
  SwColumn,
  SwGrid,
  SwLayout,
  SwLink,
} from '@kaa/ui-flanders/components';
import logo from '@kaa/ui-flanders/themes/wl/images/logo-footer.svg';
import React from 'react';
import { FooterProps } from '../../../types/footer';
import './Footer.style.scss';

export const FooterWallonia = ({
  title,
  subtitle,
  copyright,
  topLinks,
  bottomLinks,
}: FooterProps) => {
  return (
    <footer>
      <div className="footer--top">
        <SwLayout>
          <SwGrid>
            <SwColumn>
              <div className="footer--wrapper footer--wrapper_top vl-u-display-flex">
                {!!logo && (
                  <div className="logo--wrapper">
                    <img src={logo} alt={title} className="logo" />
                    <p className="logo--tagline">
                      {title}
                      {!!subtitle && <span>{subtitle}</span>}
                    </p>
                  </div>
                )}
                {topLinks && !!topLinks.length && (
                  <ul className="footer--top_list">
                    {topLinks.map(({ label, url, ...rest }) => (
                      <li key={label}>
                        <SwButton
                          tagName="a"
                          to={url}
                          title={label}
                          {...rest}
                          onClick={() =>
                            sendCustomInteractionToGTM(
                              EventCategory.FOOTER,
                              EventAction.CLICK,
                              url,
                            )
                          }
                        >
                          {label}
                        </SwButton>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </SwColumn>
          </SwGrid>
        </SwLayout>
      </div>
      <div className="footer--bottom">
        <SwLayout>
          <SwGrid>
            <SwColumn>
              <div className="footer--wrapper vl-u-display-flex">
                <p className="copyright">{copyright}</p>
                {bottomLinks && !!bottomLinks.length && (
                  <ul className="footer--bottom_list">
                    {bottomLinks.map(({ label, url, ...rest }) => (
                      <li key={label}>
                        <SwLink
                          to={url}
                          target="_blank"
                          rel="noopener noreferrer"
                          title={label}
                          {...rest}
                          onClick={() =>
                            sendCustomInteractionToGTM(
                              EventCategory.FOOTER,
                              EventAction.CLICK,
                              url,
                            )
                          }
                        >
                          {label}
                        </SwLink>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </SwColumn>
          </SwGrid>
        </SwLayout>
      </div>
    </footer>
  );
};
