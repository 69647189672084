import { Field, Formik } from 'formik';
import get from 'lodash.get';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomerStatus, RegionCode } from '@kaa/api/providers';
import { useAsyncCallback } from '@kaa/common/utils';
import { maxLength, minLength, onlyIf, required } from '@kaa/common/validation';
import { getConfig } from '@kaa/core-app/common/config';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import {
  Icon,
  SwActionGroup,
  SwAlert,
  SwButton,
  SwColumn,
  SwForm,
  SwFormGrid,
  SwInputParseOnlyDigits,
  SwInputTextField,
  SwLink,
} from '@kaa/ui-flanders/components';

import { getAlertPropsByCustomerStatus } from './PrestationsCustomerStatusScreen.utils';
import { REGION_ACCOUNT_FROZEN_BLOCKED_URL } from '../../../constants';
import { useApi } from '../../../utils';
import { createValidatorWithServerErrorHandled } from '../../../utils/validation';

const fieldNames = {
  AUTHORIZATION_ID: 'authorisationId',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
};

export const PrestationsCustomerStatusForm = () => {
  const [status, setStatus] = useState<CustomerStatus | null>(null);

  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;
  const { providers } = useApi();
  const { t } = useTranslation();

  const initialValues = {
    [fieldNames.AUTHORIZATION_ID]: '',
    [fieldNames.FIRST_NAME]: '',
    [fieldNames.LAST_NAME]: '',
  };

  const validate = createValidatorWithServerErrorHandled({
    [fieldNames.AUTHORIZATION_ID]: [required, minLength(12), maxLength(12)],
    [fieldNames.FIRST_NAME]: onlyIf(
      (_, values) => !values.firstName && !values.lastName,
      [required],
    ),
    [fieldNames.LAST_NAME]: onlyIf(
      (_, values) => !values.firstName && !values.lastName,
      [required],
    ),
  });

  const [{ loading, error }, getCustomerStatus] = useAsyncCallback(
    async (params: any) => {
      setStatus(null);
      const res = await providers.getCustomerStatus(params);
      setStatus(res.data.data.status);
    },
    [providers],
  );

  return (
    <>
      <SwColumn
        className="vl-u-bg-alt vl-u-spacer--medium"
        style={{ padding: '2rem' }}
      >
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={getCustomerStatus}
          enableReinitialize
          render={({ dirty, resetForm, handleSubmit }) => {
            return (
              <SwForm onSubmit={handleSubmit}>
                <SwFormGrid
                  modStacked
                  className="vl-u-display-flex vl-u-spacer--small"
                >
                  <SwColumn width="6" widthM="12" widthS="12">
                    <Field
                      column={{ width: '12' }}
                      labelColumn={{ width: '12' }}
                      name={fieldNames.AUTHORIZATION_ID}
                      component={SwInputTextField}
                      parse={SwInputParseOnlyDigits}
                      label={t(
                        i18nKeys.prestations.customerStatus.form.userNumber,
                      )}
                      placeholder={t(
                        i18nKeys.prestations.customerStatus.form.userNumber,
                      )}
                      minLength="12"
                      maxLength="12"
                    />
                  </SwColumn>

                  <SwColumn width="3" widthM="6" widthS="12">
                    <Field
                      column={{ width: '12' }}
                      labelColumn={{ width: '12' }}
                      name={fieldNames.LAST_NAME}
                      component={SwInputTextField}
                      label={t(i18nKeys.general.label.lastname)}
                      placeholder={t(
                        i18nKeys.prestations.customerStatus.form.lastName,
                      )}
                    />
                  </SwColumn>

                  <SwColumn width="3" widthM="6" widthS="12">
                    <Field
                      column={{ width: '12' }}
                      labelColumn={{ width: '12' }}
                      name={fieldNames.FIRST_NAME}
                      component={SwInputTextField}
                      label={t(i18nKeys.general.label.firstname)}
                      placeholder={t(
                        i18nKeys.prestations.customerStatus.form.firstName,
                      )}
                    />
                  </SwColumn>

                  <SwColumn width="6" widthM="8" widthS="12">
                    <SwActionGroup modCollapseS>
                      <SwButton
                        type="submit"
                        modLoading={loading}
                        modDisabled={loading || !dirty}
                        modIconBefore
                        icon={Icon.SYNCHRONIZE}
                      >
                        {t(i18nKeys.general.search)}
                      </SwButton>

                      <SwLink
                        type="button"
                        onClick={() => {
                          resetForm();
                          setStatus(null);
                        }}
                      >
                        {t(i18nKeys.general.label.reset)}
                      </SwLink>
                    </SwActionGroup>
                  </SwColumn>
                </SwFormGrid>
              </SwForm>
            );
          }}
        />
      </SwColumn>

      {error && (
        <SwAlert
          modError
          icon={Icon.ALERT_CIRCLE}
          title={t(i18nKeys.prestations.customerStatus.errorMessage)}
          style={{ width: 'max-content' }}
        />
      )}

      {status && (
        <SwAlert
          style={{ width: 'max-content' }}
          {...getAlertPropsByCustomerStatus(status)}
          title={t(i18nKeys.general.customerStatus[status])}
        >
          {[CustomerStatus.FROZEN, CustomerStatus.BLOCKED].includes(status) && (
            <SwLink
              to={t(REGION_ACCOUNT_FROZEN_BLOCKED_URL[regionCode])}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t(i18nKeys.prestations.customerStatus.moreInfoHere)}
            </SwLink>
          )}
        </SwAlert>
      )}
    </>
  );
};
