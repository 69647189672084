import { useAuthState } from '@kaa/auth/common';
import { dataTest } from '@kaa/core-app/providers/datatest/keys';
import { getRouterPath, Routes } from '@kaa/core-app/providers/routes';
import { AuthContext } from '@kaa/core-app/providers/types';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { Icon, SwIcon, SwLink } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const HeaderLoginLink = () => {
  const { t } = useTranslation();
  const { authUser, user, logout } = useAuthState<AuthContext>();

  if (!authUser) {
    return (
      <div className="vl-u-float-right site-bar__link site-bar__profile">
        <SwLink to={getRouterPath(Routes.LOGIN)}>
          {t(i18nKeys.navigation.login)}
        </SwLink>
      </div>
    );
  }

  if (authUser && !user) {
    return (
      <div className="vl-u-float-right site-bar__link site-bar__profile">
        <SwLink
          style={{ paddingRight: '1rem' }}
          className="vl-u-text--small "
          tagName="button"
          type="button"
          data-testid={dataTest.header.linkLogout}
          onClick={() => {
            logout();
          }}
        >
          {t(i18nKeys.navigation.logout).toUpperCase()}&nbsp;
          <SwIcon icon={Icon.LOGOUT} modLarge />
        </SwLink>
      </div>
    );
  }

  return null;
};
