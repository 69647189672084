import { i18nKeys } from '@kaa/i18n/providers/keys';
import { TFunction } from 'i18next';
import { CommonRoutes } from '../../../common/constants/routes-constant';
import { Routes } from '../routes';

export const TITLE_ROUTES = {
  [Routes.LOGIN_LOCAL]: (t: TFunction) => t(i18nKeys.navigation.login),
  [Routes.REMITTANCES]: (t: TFunction) => t(i18nKeys.navigation.remittances),
  [Routes.REMITTANCES_REIMBURSEMENT]: (t: TFunction) =>
    `${t(i18nKeys.navigation.remittances)} - ${t(
      i18nKeys.navigation.remittancesReimbursement,
    )}`,
  [Routes.PRESTATIONS]: (t: TFunction) => t(i18nKeys.navigation.prestations),
  [Routes.PRESTATIONS_HISTORY]: (t: TFunction) =>
    `${t(i18nKeys.navigation.prestations)} - ${t(
      i18nKeys.navigation.prestationsHistory,
    )}`,
  [Routes.PRESTATIONS_CUSTOMER_STATUS]: (t: TFunction) =>
    `${t(i18nKeys.navigation.prestations)} - ${t(
      i18nKeys.navigation.prestationsUserStatus,
    )}`,
  [Routes.WORKERS]: (t: TFunction) => t(i18nKeys.navigation.workers),
  [Routes.WORKERS_DETAILS]: (t: TFunction) =>
    `${t(i18nKeys.navigation.workers)} - ${t(
      i18nKeys.navigation.workerDetails,
    )}`,
  [Routes.PICKUP]: (t: TFunction) => t(i18nKeys.navigation.pickup),
  [Routes.PICKUP_BILLING]: (t: TFunction) =>
    `${t(i18nKeys.navigation.pickup)} - ${t(
      i18nKeys.navigation.pickupBilling,
    )}`,
  [Routes.ENTERPRISE]: (t: TFunction) => t(i18nKeys.navigation.enterprise),
  [Routes.ENTERPRISE_DETAILS]: (t: TFunction) =>
    `${t(i18nKeys.navigation.enterprise)} - ${t(
      i18nKeys.navigation.enterpriseDetails,
    )}`,
  [Routes.ENTERPRISE_DETAILS_CONTACT_PERSON]: (t: TFunction) =>
    `${t(i18nKeys.navigation.enterprise)} - ${t(
      i18nKeys.navigation.contactPerson,
    )}`,
  [Routes.ENTERPRISE_DETAILS_CONTACT_PERSON_DETAILS]: (t: TFunction) =>
    `${t(i18nKeys.navigation.enterprise)} - ${t(
      i18nKeys.navigation.contactPerson,
    )} - ${t(i18nKeys.navigation.contactPersonDetails)}`,
  [Routes.ENTERPRISE_DETAILS_VISIBILITY]: (t: TFunction) =>
    `${t(i18nKeys.navigation.enterprise)} - ${t(
      i18nKeys.navigation.activities,
    )} - ${t(i18nKeys.navigation.visibility)}`,
  [Routes.ENTERPRISE_ACTIVITIES]: (t: TFunction) =>
    `${t(i18nKeys.navigation.enterprise)} - ${t(
      i18nKeys.navigation.activities,
    )}`,
  [CommonRoutes.DASHBOARD]: (t: TFunction) => t(i18nKeys.navigation.dashboard),
  [CommonRoutes.LOGIN]: (t: TFunction) => t(i18nKeys.navigation.login),
  [CommonRoutes.SERVICE_UNAVAILABLE]: (t: TFunction) =>
    t(i18nKeys.navigation.serviceUnavailable),
  [CommonRoutes.PAGE_404]: (t: TFunction) => t(i18nKeys.navigation.error404),
};
