import get from 'lodash.get';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { RegionCode } from '@kaa/api/providers';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { SwColumn, SwContainer, SwGrid } from '@kaa/ui-flanders/components';

import { PrestationsCustomerStatusForm } from './PrestationsCustomerStatusForm';
import { getConfig } from '../../../../common/config';
import { PageHeader } from '../../../components';
import { REGION_NAME } from '../../../constants';

export const PrestationsCustomerStatusScreen = () => {
  const { t } = useTranslation();
  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;

  return (
    <SwContainer>
      <SwGrid modStacked>
        <SwColumn>
          <PageHeader
            className="vl-u-spacer"
            title={t(i18nKeys.navigation.prestationsUserStatus)}
            subTitle={t(i18nKeys.prestations.customerStatus.introduction, {
              region: t(REGION_NAME[regionCode]),
            })}
          />
        </SwColumn>

        <SwColumn>
          <PrestationsCustomerStatusForm />
        </SwColumn>
      </SwGrid>
    </SwContainer>
  );
};
