import { useAuthState } from '@kaa/auth/common';
import { dataTest } from '@kaa/core-app/providers/datatest/keys';
import { AuthContext } from '@kaa/core-app/providers/types';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { Icon, SwBadge, SwIcon, SwLink } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import { getPath, Routes } from '@kaa/core-app/providers/routes';

export const HeaderProfile = () => {
  const { t } = useTranslation();
  const { authUser, user } = useAuthState<AuthContext>();

  if (!authUser || !user) {
    return <div className="site-bar__profile-mobile" />;
  }

  return (
    <>
      <div className="site-bar__link site-bar__profile">
        <SwLink
          style={{ paddingRight: '1rem' }}
          className="vl-u-text--small "
          tagName="button"
          type="button"
          data-testid={dataTest.header.linkLogout}
          onClick={() => {
            navigate(getPath(Routes.LOGOUT));
          }}
        >
          {t(i18nKeys.navigation.logout).toUpperCase()}&nbsp;
          <SwIcon icon={Icon.LOGOUT} modLarge />
        </SwLink>
        {`${user.name}`}
      </div>
      <div className="site-bar__profile-mobile">
        <SwBadge className="profile-badge" icon={Icon.USER_CIRCLE} />
        <span>{user.name}</span>
      </div>
    </>
  );
};
